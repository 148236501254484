<template>
  <v-sheet :color="wsLIGHTCARD" class="fill-height">

    <login-side-bar/>


    <v-row class="no-gutters flex-wrap flex-column fill-height " >

      <!-- Chats Header -->
      <v-col cols="auto" class="shrink">
        <homeHeaderFine />
      </v-col>
      <!-- Chats Content -->
      <v-col ref="content" cols="auto" class="grow overflow-y-auto " style="height: 100px; "  >
        <v-fade-transition mode="out-in">
          <router-view @hideFooter="displayFooter = !$event" />
        </v-fade-transition>
        <home-footer-fine v-if="displayFooter" />
      </v-col>


    </v-row>


    <registerBusiness v-model="$store.state.homepage.displayRegisterBusiness"/>
    <homeContactFormDialog v-model="$store.state.homepage.displayContactForm" />

  </v-sheet>
</template>

<script>

import loginSideBar from "@/components/auth/loginSideBar";
import homeHeaderFine from "@modules/fine_homepage/components/UI/homeHeaderFine.vue";
import homeFooterFine from "@modules/fine_homepage/components/UI/homeFooterFine.vue";
import registerBusiness from "@/components/UI/components/registerBusiness";
import homeContactFormDialog from "@/components/pages/homepage_westudy/UI/homeContactFormDialog";
import referral from "@/mixins/referral";

export default {
  name: "PageHomepage",
  mixins : [referral],
  components : {
    registerBusiness,
    homeHeaderFine,
    homeFooterFine,
    homeContactFormDialog,
    loginSideBar,
  },
  data: ()=> ({
    displayFooter : true,
    displayDialog : false,
    loading : false,
    leadDialog : false,
  }),
  watch : {
    $route (){
      if ( this.$refs.content ) {
        let content = this.$refs.content
        if ( content ) {
          content.scrollTop = 0
        }
      }
      window.scrollTo(0, 0)
    }
  },

  methods : {

  },
  mounted() {
    this.$store.state.isHomepage = true
    this.CHECK_PARTNER_REFFERAL()
  },
  beforeMount() {
    this.$store.state.isHomepage = false
  }


}
</script>

<style lang="scss" scoped>
.main_section {
  width:1100px
}
</style>